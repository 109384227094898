.header-app-css-animation {
  height: 100vh !important;
  align-items: flex-start !important;
  position: fixed !important;
  padding: 10px !important;
  background: #fff !important;
  //   transition: visibility 4s, opacity 0.2s linear !important;
  transform: translateY(-100);
  transition: 0.2s linear;
  .join-us-btn {
    display: none;
  }
  .bread-cumb {
    display: none !important;
  }
  .close-modal-icon {
    display: block !important;
    position: absolute;
    right: 6vw;
    top: 20px;
  }
  .logo-app {
    .app-logo-img {
      position: absolute !important;
      top: 15px !important;
      left: 7vw !important;
    }
  }

  .navigation-container1::before {
    content: "";
    position: fixed;
    top: 78px;
    left: 0;
    width: 100%;
    height: 0.1px;
    box-shadow: 0px 0px 1px grey;
    z-index: 100;
  }

  .navigation-container1 {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 18% !important;
    height: 20vh !important;
    width: 100%;
    left: 0px;
    right: 0px;
    .active-route1 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #66bb6a;
      text-decoration: none;
    }
    .default-route1 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      text-decoration: none;
      cursor: pointer;
    }
    .default-route1:hover {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #66bb6a;
      cursor: pointer;
    }
    .join-us-btn1 {
      padding: 12px 35px;
      background: linear-gradient(180deg, #66bb6a 0%, #33a539 100%);
      border-radius: 5px;
      text-transform: none;
      color: #ffffff;
    }
  }
}

.di-none {
  display: none;
}
