.about-us-container-main {
  margin-top: 5.4vw;
  position: relative;
  .about-back-image {
    width: 100%;
    padding-top: 5%;
    min-height: 28vh;
    min-width: 97vw;
  }

  #location {
    animation: locationicon 4s linear infinite;
    transform-origin: top;
    transform-box: fill-box;
  }

  @keyframes locationicon {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(10px);
    }
    50% {
      transform: translateY(5px);
    }
  }

  .about-back-image-mobile {
    width: 100%;
    height: 100%;
    display: none;
  }
  .home-page-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 2%;
    left: 7vw;
    .home-title {
      font-style: normal;
      font-weight: 700;
      font-size: 3.4vw;
      color: #000000;
    }
    .home-title-green {
      font-style: normal;
      font-weight: 700;
      font-size: 3.4vw;
      color: #66bb6a;
    }

    @media (max-width: 850px) and (min-width: 700px) {
      .home-title {
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        color: #000000;
      }
      .home-title-green {
        font-style: normal;
        font-weight: 700;
        font-size: 27px;
        color: #66bb6a;
      }
    }
    @media (max-width: 1000px) and (min-width: 850px) {
      .home-title {
        font-style: normal;
        font-weight: 700;
        font-size: 37px;
        color: #000000;
      }
      .home-title-green {
        font-style: normal;
        font-weight: 700;
        font-size: 37px;
        color: #66bb6a;
      }
    }
  }

  .about-us-container {
    padding: 2% 17%;
    margin-top: -5px;
    background-color: #eefcef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      color: #1d5f00;
      padding-bottom: 2%;
      text-align: center;
    }
    .desc {
      font-style: normal;
      font-size: 20px;
      line-height: 23px;
      color: #616161;
      text-align: center;
      font-family: "Chivo", sans-serif;
      font-weight: initial;
    }
    .desc1 {
      display: none;
      font-style: normal;
      font-size: 20px;
      line-height: 23px;
      color: #616161;
      text-align: center;
      font-family: "Chivo", sans-serif;
      font-weight: initial;
    }
    @media (max-width: 500px) {
      .desc {
        display: none;
      }
      .desc1 {
        display: unset;
        font-size: 16px;
      }
    }
  }
  .card-container {
    padding: 5% 7vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .card0 {
      transition: transform 0.2s;
      width: 47%;
      background: #66bb6a;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2% 3%;
      border-radius: 12px;
      #circlex {
        fill: #eefcef;
      }
      #itemx {
        fill: #66bb6a;
      }
      #outer-circle {
        stroke: #eefcef;
      }
      #inner-circle {
        fill: #eefcef;
      }
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #eefcef;
        margin: 5% 0%;
      }
      .desc {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 5%;
        font-family: "Chivo", sans-serif;
        font-weight: initial;
        color: #eefcef;
        img {
          padding-right: 3%;
        }
      }
    }
    .card1 {
      transition: transform 0.2s;
      width: 47%;
      background: #eefcef;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2% 3%;
      border-radius: 12px;
      #circlex {
        fill: #66bb6a;
      }
      #itemx {
        fill: #eefcef;
      }
      #outer-circle {
        stroke: #66bb6a;
      }
      #inner-circle {
        fill: #66bb6a;
      }
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #66bb6a;
        margin: 5% 0%;
      }
      .desc {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #66bb6a;
        margin-bottom: 5%;
        font-family: "Chivo", sans-serif;
        font-weight: initial;
        img {
          padding-right: 3%;
        }
      }
    }
    .card0:hover {
      -ms-transform: scale(1.1); /* IE 9 */
      -webkit-transform: scale(1.1); /* Safari 3-8 */
      transform: scale(1.1);
    }
    .card1:hover {
      -ms-transform: scale(1.1); /* IE 9 */
      -webkit-transform: scale(1.1); /* Safari 3-8 */
      transform: scale(1.1);
    }
    // .card1:hover ~ .card0 {
    //   width: 49%;
    //   background: #eefcef;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-start;
    //   padding: 2% 3%;
    //   border-radius: 12px;
    //   #circlex{
    //     fill: #66bb6a;
    //     }
    //     #itemx{
    //     fill: #eefcef;
    //     }
    //   .title {
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 24px;
    //     line-height: 28px;
    //     color: #66bb6a;
    //     margin: 5% 0%;
    //   }
    //   .desc {
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     color: #66bb6a;
    //     margin-bottom: 5%;
    //     font-family: "Chivo", sans-serif;
    //     font-weight: initial;
    //     img {
    //       padding-right: 3%;
    //     }
    //   }
    // }
    // .card0:hover ~ .card1 {
    //   width: 49%;
    //   background: #66bb6a;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-start;
    //   padding: 2% 3%;
    //   border-radius: 12px;
    //   #circlex {
    //     fill: #eefcef;
    //   }
    //   #itemx {
    //     fill: #66bb6a;
    //   }
    //   .title {
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 24px;
    //     line-height: 28px;
    //     color: #eefcef;
    //     margin: 5% 0%;
    //   }
    //   .desc {
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     margin-bottom: 5%;
    //     font-family: "Chivo", sans-serif;
    //     font-weight: initial;
    //     color: #eefcef;
    //     img {
    //       padding-right: 3%;
    //     }
    //   }
    // }
    // .card0:hover {
    //   width: 49%;
    //   background: #eefcef;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-start;
    //   padding: 2% 3%;
    //   border-radius: 12px;
    //   #circlex {
    //     fill: #66bb6a;
    //   }
    //   #itemx {
    //     fill: #eefcef;
    //   }
    //   .title {
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 24px;
    //     line-height: 28px;
    //     color: #66bb6a;
    //     margin: 5% 0%;
    //   }
    //   .desc {
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     color: #66bb6a;
    //     margin-bottom: 5%;
    //     font-family: "Chivo", sans-serif;
    //     font-weight: initial;
    //     img {
    //       padding-right: 3%;
    //     }
    //   }
    // }
    // .card1:hover {
    //   width: 49%;
    //   background: #66bb6a;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-start;
    //   padding: 2% 3%;
    //   border-radius: 12px;
    //   #circlex {
    //     fill: #eefcef;
    //   }
    //   #itemx {
    //     fill: #66bb6a;
    //   }
    //   .title {
    //     font-style: normal;
    //     font-weight: 700;
    //     font-size: 24px;
    //     line-height: 28px;
    //     color: #eefcef;
    //     margin: 5% 0%;
    //   }
    //   .desc {
    //     display: flex;
    //     justify-content: flex-start;
    //     align-items: center;
    //     margin-bottom: 5%;
    //     font-family: "Chivo", sans-serif;
    //     font-weight: initial;
    //     color: #eefcef;
    //     img {
    //       padding-right: 3%;
    //     }
    //   }
    // }
  }
  .middle-container {
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 12%;
    .back-image {
      width: 100%;
      padding-top: 5%;
    }
    .back-image-mobile {
      width: 100%;
      padding-top: 5%;
      display: none;
    }
    .growdth-title {
      position: absolute;
      top: 12%;
      font-style: normal;
      font-weight: 700;
      line-height: 42px;
      align-self: center;
      font-size: 36px;
      color: #1d5f00;
      padding-bottom: 2%;
      text-align: center;
    }
    .about-us-container1 {
      position: absolute;
      bottom: 0%;
      padding: 2% 17%;
      margin-top: -5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        color: #1d5f00;
        padding-bottom: 2%;
        text-align: center;
      }
      .desc {
        font-style: normal;
        color: #616161;
        text-align: center;
        font-family: "Chivo", sans-serif;
        font-weight: initial;
        font-size: 1.8vw;
      }
      .desc1 {
        display: none;
        font-style: normal;
        color: #616161;
        text-align: center;
        font-family: "Chivo", sans-serif;
        font-weight: initial;
        font-size: 1.8vw;
      }
      @media (max-width: 500px) {
        .desc {
          font-style: normal;
          color: #616161;
          text-align: center;
          font-family: "Chivo", sans-serif;
          font-weight: initial;
          font-size: 16px;
          display: none;
        }
        .desc1 {
          display: unset;
          font-style: normal;
          color: #616161;
          text-align: center;
          font-family: "Chivo", sans-serif;
          font-weight: initial;
          font-size: 16px;
        }
      }
    }
  }
  .about-footer {
    position: relative;
    display: flex;
    justify-content: center;
    .footer-img-main {
      width: 100%;
      padding-right: 20px;
      padding-top: 60px;
      padding-bottom: 200px;
      padding-left: 20px;
    }
    .footer-img-mobile {
      width: 100%;
      display: none;
    }
    .join-us-btn {
      background: linear-gradient(180deg, #66bb6a 0%, #33a539 100%);
      border-radius: 5px;
      text-transform: none;
      padding: 14px 45px;
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      position: absolute;
      align-self: center;
      bottom: 24%;
    }
  }
  @media (max-width: 500px) {
    .card-container {
      padding: 9% 7vw;
      .card0 {
        width: 100%;
        margin-bottom: 20px;
        margin-top: 15px;
        padding: 8% 8%;
        color: #eefcef;
      }
      .card1 {
        width: 100%;
        padding: 8% 8%;
        color: #1d5f00;
        font-family: "Chivo", sans-serif;
        font-weight: initial;
      }
    }
    .about-back-image {
      width: 100%;
      display: none;
    }
    .about-back-image-mobile {
      width: 100%;
      display: unset;
      padding: 30% 4% 20% 6%;
    }
    .home-page-text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: -1%;
      left: 7vw;
      padding-top: 30px;
      .home-title {
        font-style: normal;
        font-weight: 700;
        color: #000000;
        font-size: 32px;
        line-height: 2.5rem;
      }
      .home-title-green {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        color: #66bb6a;
      }

      @media (max-width: 850px) and (min-width: 700px) {
        .home-title {
          font-style: normal;
          font-weight: 700;
          font-size: 27px;
          color: #000000;
        }
        .home-title-green {
          font-style: normal;
          font-weight: 700;
          font-size: 27px;
          color: #66bb6a;
        }
      }
      @media (max-width: 1000px) and (min-width: 850px) {
        .home-title {
          font-style: normal;
          font-weight: 700;
          font-size: 37px;
          color: #000000;
        }
        .home-title-green {
          font-style: normal;
          font-weight: 700;
          font-size: 37px;
          color: #66bb6a;
        }
      }
    }
    .middle-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      padding-bottom: 6%;
      .back-image {
        width: 100%;
        padding-top: 5%;
        display: none;
      }
      .back-image-mobile {
        width: 100%;
        padding-top: 5%;
        display: block;
      }
      .growdth-title {
        position: absolute;
        top: 12%;
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 42px;
        color: #1d5f00;
        align-self: center;
        display: none;
      }
      .about-us-container1 {
        padding: 30% 15px 2% 15px;
        margin-top: -5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: unset;
        bottom: 0%;
        .title {
          font-style: normal;
          font-weight: 700;
          font-size: 34px;
          color: #1d5f00;
          padding-bottom: 5%;
          text-align: center;
        }
        .desc {
          font-style: normal;
          color: #616161;
          text-align: center;
          font-weight: 400;
        }
      }
    }
    .about-footer {
      position: relative;
      .footer-img-main {
        width: 100%;
        display: none;
      }
      .footer-img-mobile {
        width: 100%;
        display: block;
        padding-top: 25px;
        padding-bottom: 60px;
      }
      .join-us-btn {
        background: linear-gradient(180deg, #66bb6a 0%, #33a539 100%);
        border-radius: 5px;
        text-transform: none;
        padding: 14px 45px;
        color: #ffffff;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        position: absolute;
        align-self: center;
        bottom: 15%;
      }
    }
    .about-us-container {
      padding: 17% 15px;
      margin-top: -5px;
      background-color: #eefcef;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        color: #1d5f00;
        padding-bottom: 5%;
        text-align: center;
      }
      .desc {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #616161;
        text-align: center;
      }
    }
  }
}

@media (max-width: 500px) {
  .about-us-container-main {
    margin-top: 13.4vw;
  }
}
