* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.textdeco-none {
  text-decoration: none;
}
.pointer {
  cursor: pointer;
}

#spinner-bull {
  width: 50px;
  height: 50px;
  animation: zoom-in-zoom-out 2s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.8, 1.8);
  }
  100% {
    transform: scale(1, 1);
  }
}
