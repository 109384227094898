.footer-homepage {
  display: flex;
  flex-wrap: wrap-reverse;
  padding: 4% 0% 6% 0%;
  background-image: url("./footer-normal-back.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  background-position: bottom;
  background-size: cover;
  border-top: 2px solid #66bb6a;
  .social-medias1 {
    display: none;
  }
  .footer-logo {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-right: 3%;
    .footer-logo-img {
      width: 30%;
    }
    .social-medias {
      width: 34%;
      padding-top: 4%;
      display: flex;
      justify-content: space-evenly;
      .social-media-icon {
      }
    }
  }
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #1d5f00;
    padding-bottom: 3%;
  }
  .list-item {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1d5f00;
    padding-bottom: 3%;
    cursor: pointer;
  }
  .list-item:hover {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #1d5f00;
    padding-bottom: 3%;
    text-decoration: underline;
  }
  .contact-container {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
  .footer-navigation {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
  }
  .footer-nav-container {
    display: flex;
    flex-wrap: wrap-reverse;
    width: 50%;
    .contact-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
    }
    .footer-navigation {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
    }
  }
}

@media (max-width: 500px) {
  .footer-homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("./footer-mobile-back.svg");
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: bottom;
    background-size: cover;
  }
}

@media (max-width: 850px) {
  .footer-homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .social-medias1 {
      width: 34%;
      padding-top: 4%;
      display: flex;
      justify-content: space-evenly;
      .social-media-icon {
      }
    }
    .footer-logo {
      padding: 20px 0px 30px 0px;
      width: 100%;
      align-items: center;
      justify-content: center;
      .footer-logo-img {
        width: 36%;
      }
      .social-medias {
        display: none;
      }
    }
    .footer-nav-container {
      display: flex;
      flex-wrap: nowrap;
      width: 80%;
      .contact-container {
        padding-top: 20px;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      .footer-navigation {
        padding-top: 20px;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    }
    @media (max-width: 500px) {
      .footer-nav-container {
        display: flex;
        flex-wrap: wrap-reverse;
        width: 80%;
        .contact-container {
          padding-top: 20px;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        .footer-navigation {
          padding-top: 20px;
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
