.heder-app-container {
  height: 86px;
  background: rgba(255, 255, 255, 0.9);
  // box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 7vw;
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000000;
  .close-modal-icon {
    display: none;
  }
  .navigation-container1 {
    display: none;
  }

  .logo-app {
    .app-logo {
      height: 3.5vw;
    }
  }
  
  @media (max-width: 870px) and (min-width: 820px) {
    .logo-app {
      width: 20% !important;
    }

    .navigation-container {
      width: 80% !important;
    }
  }
  @media (max-width: 820px) {
    .default-route,
    .active-route {
      display: none;
    }
    .join-us-btn {
      padding: 9px 20px !important;
      font-size: smaller;
    }
    .bread-cumb {
      display: block;
      padding-left: 30px;
    }
    .navigation-container {
      justify-content: flex-end !important;
    }
  }
  .logo-app {
    width: 30%;
  }

  .navigation-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25vw;
    width: 80%;
    .active-route {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #66bb6a;
      text-decoration: none;
    }
    .default-route {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      text-decoration: none;
      cursor: pointer;
    }
    .default-route:hover {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #66bb6a;
      cursor: pointer;
    }
    .join-us-btn {
      padding: 12px 35px;
      background: linear-gradient(180deg, #66bb6a 0%, #33a539 100%);
      border-radius: 5px;
      text-transform: none;
      color: #ffffff;
    }
    .bread-cumb {
      display: none;
    }
    @media (max-width: 820px) {
      .bread-cumb {
        display: block;
      }
    }
    @media (max-width: 520px) {
      .join-us-btn {
        padding: 4px 10px !important;
        font-size: smaller;
      }
    }
  }
}

@media (max-width: 820px) {
  .heder-app-container {
    height: 84px;
  }
}
@media (max-width: 500px) {
  .logo-app {
    .app-logo-img {
      height: 50px;
    }
  }
}
