.flip-card {
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transition: height 1s;
  transform-style: preserve-3d;
  background-color: transparent;
}

@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.flip-card:hover .flip-card-inner {
  .flip-card-back {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: createBox 0.5s;
  }
  .hide-class-hover {
    visibility:hidden;
  }
  .image-user-container {
    overflow: unset;
  }
  .image-user {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  display: none;
}

.flip-card-front {
  background-color: #ffffff;
  color: black;
}

.flip-card-back {
  background-color: #66bb6ae5;
  opacity: 1;
  color: #ffffff;
  height: 100%;
  position: absolute;
  top: 0px;
  border-radius: 24px;
  padding: 15px;
}

.head-title {
  font-size: 2.8vw;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}
.testimonials-title {
  font-size: 3vw;
  font-weight: bold;
  color: #1d5f00;
}
.switch-titles {
  right: 5%;
  position: absolute;
  color: #6e706e;
  font-size: 1.2vw;
}

@media (max-width: 500px) {
  .head-title {
    font-size: 4vw;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
  }
  .testimonials-title {
    font-size: 4.5vw;
    font-weight: bold;
    color: #1d5f00;
  }
  .switch-titles {
    right: 5%;
    position: absolute;
    color: #6e706e;
    font-size: 2vw;
  }
}
