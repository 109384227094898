.home-page-container {
  .testimonial-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .go-to-btn-testimonial {
      font-size: 2.5vw;
    }
    @media (max-width: 500px) {
      .go-to-btn-testimonial {
        font-size: 22px;
      }
      .static-string-title {
        font-style: normal;
        font-weight: 700;
        font-size: 2.5vw;
        color: #66bb6a;
      }
    }
  }
  .home-back-image {
    width: 100%;
    min-width: 98vw;
    min-height: 60vh;
  }
  .home-back-image-mobile {
    width: 100%;
    display: none;
  }
  @media (max-width: 500px) {
    .home-back-image {
      width: 100%;
      display: none;
    }
    .home-back-image-mobile {
      width: 100%;
      display: unset;
    }
    .go-to-btn-testimonial {
      font-size: 22px;
    }
  }
  margin-top: 5.4vw;
  position: relative;

  #Cart-wheel {
    animation: wheel 12s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  @keyframes wheel {
    from {
      transform: rotateZ(360deg);
    }
    to {
      transform: rotateZ(0deg);
    }
  }

  #grass-motion {
    animation: run 6s linear infinite;
    transform-origin: left;
    transform-box: fill-box;
  }
  @keyframes run {
    0% {
      transform: translateX(30px);
    }
    100% {
      transform: translateX(30px);
    }
    50% {
      transform: translateX(10px);
    }
  }
  #farmer-cart {
    animation: cart 4s linear infinite;
    transform-origin: left;
    transform-box: fill-box;
  }

  @keyframes cart {
    0% {
      transform: translateX(30px);
    }
    100% {
      transform: translateX(30px);
    }
    50% {
      transform: translateX(10px);
    }
  }
  #icons {
    animation: icons 4s linear infinite;
    transform-origin: top;
    transform-box: fill-box;
  }

  @keyframes icons {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(10px);
    }
    50% {
      transform: translateY(5px);
    }
  }

  // hhjjhjh

  #Cart-wheel1 {
    animation: wheel1 12s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  @keyframes wheel1 {
    from {
      transform: rotateZ(360deg);
    }
    to {
      transform: rotateZ(0deg);
    }
  }
  #grass-motion1 {
    animation: run1 6s linear infinite;
    // transform-origin: left;
    margin-right: 20px;
    transform-box: fill-box;
  }
  @keyframes run1 {
    0% {
      transform: translateX(50px);
    }
    100% {
      transform: translateX(50px);
    }
    50% {
      transform: translateX(0px);
    }
  }
  #farmer-cart1 {
    animation: cart1 4s linear infinite;
    transform-origin: right;
    margin-right: 20px;
    transform-box: fill-box;
  }

  @keyframes cart1 {
    0% {
      transform: translateX(30px);
    }
    100% {
      transform: translateX(30px);
    }
    50% {
      transform: translateX(10px);
    }
  }
  #icons1 {
    animation: icons1 4s linear infinite;
    transform-origin: top;
    transform-box: fill-box;
  }

  @keyframes icons1 {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(10px);
    }
    50% {
      transform: translateY(5px);
    }
  }

  #rear-wheel {
    animation: rearwheel1 12s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  @keyframes rearwheel1 {
    from {
      transform: rotateZ(360deg);
    }
    to {
      transform: rotateZ(0deg);
    }
  }

  #front-wheel {
    animation: rearwheel2 12s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  @keyframes rearwheel2 {
    from {
      transform: rotateZ(360deg);
    }
    to {
      transform: rotateZ(0deg);
    }
  }

  #Group_4 {
    animation: rearwheel3 12s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
  }

  @keyframes rearwheel3 {
    from {
      transform: rotateZ(360deg);
    }
    to {
      transform: rotateZ(0deg);
    }
  }
  .home-page-container-abs {
    position: absolute;
    padding-left: 7vw;
    top: 2.5vw;
    .home-page-text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .home-title {
        font-style: normal;
        font-weight: 700;
        font-size: 3.4vw;
        color: #000000;
      }
      .home-title-green {
        font-style: normal;
        font-weight: 700;
        font-size: 3.4vw;
        color: #66bb6a;
      }

      @media (max-width: 850px) and (min-width: 700px) {
        .home-title {
          font-style: normal;
          font-weight: 700;
          font-size: 27px;
          color: #000000;
        }
        .home-title-green {
          font-style: normal;
          font-weight: 700;
          font-size: 27px;
          color: #66bb6a;
        }
      }
      @media (max-width: 1000px) and (min-width: 850px) {
        .home-title {
          font-style: normal;
          font-weight: 700;
          font-size: 37px;
          color: #000000;
        }
        .home-title-green {
          font-style: normal;
          font-weight: 700;
          font-size: 37px;
          color: #66bb6a;
        }
      }
    }
    .desc-container {
      padding: 37px 0px 35px 0px;
      max-width: 550px;
      .home-page-decription {
        font-style: normal;
        font-weight: 400;
        font-size: 1.2vw;
        color: #616161;
      }
    }
    .learn-more-btn {
      background: linear-gradient(180deg, #66bb6a 0%, #33a539 100%);
      border-radius: 5px;
      padding: 12px 35px;
      color: #ffffff;
      text-decoration: none;
      text-transform: none;
    }
    @media (max-width: 850px) and (min-width: 700px) {
      .desc-container {
        padding: 25px 0px 20px 0px;
        max-width: 550px;
        .home-page-decription {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #616161;
        }
      }
      .learn-more-btn {
        background: linear-gradient(180deg, #66bb6a 0%, #33a539 100%);
        border-radius: 5px;
        padding: 6px 12px;
        color: #ffffff;
        text-decoration: none;
        text-transform: none;
        font-size: smaller;
      }
    }
    @media (max-width: 1000px) and (min-width: 850px) {
      .desc-container {
        padding: 30px 0px 25px 0px;
        max-width: 550px;
        .home-page-decription {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #616161;
        }
      }
    }
    @media (max-width: 700px) {
      .desc-container {
        padding: 12px 0px 10px 0px;
        max-width: 550px;
        .home-page-decription {
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #616161;
        }
      }
      .learn-more-btn {
        background: linear-gradient(180deg, #66bb6a 0%, #33a539 100%);
        border-radius: 5px;
        color: #ffffff;
        text-decoration: none;
        text-transform: none;
        padding: 4px 10px;
        font-size: xx-small;
      }
    }
  }
  .about-us-container {
    padding: 2% 17%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      color: #1d5f00;
      padding-bottom: 2%;
    }
    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #616161;
    }
  }

  .diagram-one {
    margin: 53px 0px;
    position: relative;
    background-image: url("./right-green.svg");
    background-position: right;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    height: 100%;
    .centeral-diagram {
      height: 67vh;
      width: -webkit-fill-available;
      padding-bottom: 3%;
    }
  }
  .milestone-container {
    width: 100%;
    height: auto;
    background: #eefcef;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;
    }
    .sub-title {
      font-weight: 400;
      font-size: 20px;
      color: #6e706e;
      text-align: center;
    }
    .title-main {
      padding-top: 4%;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      color: #1d5f00;
    }
    .title {
      padding-top: 4%;
      padding-bottom: 6%;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 42px;
      color: #1d5f00;
    }
    .info-container {
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;
      width: 100%;
      padding: 0px 30px;
      padding-bottom: 4%;
      flex-wrap: wrap;
      margin: 0px;
      .info {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: flex-start;
        background-color: #ffffff;
        padding: 25px 18px;
        height: 100%;
        min-width: 220px;
        .title {
          font-weight: 700;
          font-size: 48px;
          line-height: 56px;
          background: linear-gradient(180deg, #66bb6a 0%, #3a8a3d 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
        .description {
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 23px;
          text-align: center;
          color: #616161;
          text-align: left;
        }
      }
    }
  }

  @media (max-width: 500px) {
    .cycle-diagram {
      .txt-one {
        display: none;
      }
      .txt-two {
        display: none;
      }
    }
  }
  .cycle-diagram {
    padding-top: 5%;
    position: relative;
    display: flex;
    justify-content: center;
    .cycle-diagram-img {
      height: 100%;
      width: 100%;
    }
    .cycle-diagram-img-mobile {
      height: 100%;
      width: 100%;
      display: none;
    }

    .txt-one {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: 23%;
      left: 13%;
      .first-line {
        font-style: normal;
        font-weight: 500;
        font-size: 3vw;
        text-align: right;
        color: #343434;
      }
      .second-line {
        font-style: normal;
        font-weight: 500;
        font-size: 3vw;
        text-align: right;
        color: #1d5f00;
      }
    }
    .txt-two {
      display: flex;
      flex-direction: column;
      font-style: normal;
      position: absolute;
      top: 66%;
      right: 12%;
      .first-line {
        font-style: normal;
        font-weight: 500;
        font-size: 3vw;
        text-align: right;
        color: #343434;
      }
      .second-line {
        font-style: normal;
        font-weight: 500;
        font-size: 3vw;
        text-align: right;
        color: #1d5f00;
      }
    }
    @media (max-width: 500px) {
      .cycle-diagram-img {
        height: 100%;
        width: 100%;
        display: none;
      }
      .cycle-diagram-img-mobile {
        height: 100%;
        width: 100%;
        display: unset;
      }
      .txt-one {
        display: none;
      }
      .txt-two {
        display: none;
      }
    }
    .slider-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 12vw;
      width: 50%;
      align-self: center;
      .awssld__content {
        background-color: unset;
      }
      .awssld__timer {
        background-color: unset !important;
      }
      .awssld__bullets {
        bottom: 2%;
        .awssld__bullets--active {
          background: #66bb6a;
        }
        button {
          background: #e6e6e6;
          height: 8px;
          width: 8px;
        }
      }
      .slider-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-user {
          padding-bottom: 2vw;
          height: 6vw;
        }
        .comment-text {
          font-style: italic;
          font-weight: 400;
          font-size: 1.5vw;
          text-align: center;
          color: #616161;
        }
        .comment-title-name {
          color: #1d5f00;
          font-weight: 400;
          font-size: 1.8vw;
          padding-bottom: 12px;
          text-align: center;
        }
      }
    }
    @media (max-width: 500px) {
      .static-container-text {
        position: absolute;
        bottom: 23%;
        display: flex;
        justify-content: center;
        align-items: center;
        .static-string-title {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          color: #66bb6a;
          text-align: center;
          padding: 0px 26px;
        }
      }
      .slider-container {
        position: absolute;
        bottom: 12%;
        width: 90%;
        align-self: center;
        .awssld__content {
          background-color: unset;
        }
        .awssld__timer {
          background-color: unset !important;
        }
        .awssld__bullets {
          bottom: 2%;
          .awssld__bullets--active {
            background: #66bb6a;
          }
          button {
            background: #e6e6e6;
            height: 8px;
            width: 8px;
          }
        }
        .slider-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          .img-user {
            padding-bottom: 1.5vw;
            height: 16vw;
          }
          .comment-text {
            font-style: italic;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            color: #616161;
          }
          .comment-title-name {
            color: #1d5f00;
            font-weight: 400;
            font-size: 16px;
            padding-bottom: 12px;
            text-align: center;
          }
        }
      }
    }
  }
  @media (max-width: 500px) {
    .diagram-one {
      padding-top: 25px;
      .centeral-diagram {
        display: none;
      }
      .centeral-diagram-mobile {
        height: 65vh;
        width: -webkit-fill-available;
      }
    }
  }
  @media (min-width: 500px) {
    .diagram-one {
      .centeral-diagram-mobile {
        display: none;
      }
    }
  }
  @media (max-width: 500px) {
    .home-page-container-abs {
      position: unset;
      padding: 0px 7vw;
      padding-top: 30px;
      .home-page-text-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .home-title {
          font-style: normal;
          font-weight: 700;
          font-size: 34px;
          color: #000000;
        }
        .home-title-green {
          font-style: normal;
          font-weight: 700;
          font-size: 34px;
          color: #66bb6a;
        }
      }
      .desc-container {
        padding: 37px 0px 35px 0px;
        max-width: 550px;
        .home-page-decription {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #616161;
        }
      }
      .learn-more-btn {
        background: linear-gradient(180deg, #66bb6a 0%, #33a539 100%);
        border-radius: 5px;
        padding: 10px 32px;
        color: #ffffff;
        text-decoration: none;
        text-transform: none;
        font-size: 16px;
      }
    }
    .about-us-container {
      padding: 20% 7vw 0% 7vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        color: #1d5f00;
        padding-bottom: 2%;
      }
      .desc {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        color: #616161;
      }
    }
    .milestone-container {
      width: 100%;
      height: auto;
      background: #eefcef;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      .title {
        padding-top: 4%;
        padding-bottom: 6%;
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 42px;
        color: #1d5f00;
      }
      .info-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        padding-bottom: 6%;
        flex-wrap: wrap;
        margin: 0px;
        .info {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;
          background-color: #ffffff;
          padding: 36px 36px 36px 36px;
          .title {
            font-weight: 700;
            font-size: 48px;
            line-height: 56px;
            background: linear-gradient(180deg, #66bb6a 0%, #3a8a3d 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
          .description {
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 23px;
            text-align: center;
            color: #616161;
          }
        }
      }
    }
  }
}
